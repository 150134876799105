@import './index';

.container {
  display: grid;
  grid-template-columns: $sidebar-width calc(100% - $sidebar-width);
  grid-template-areas: 'sidebar' 'main';
  height: 100vh;
  overflow: hidden;
  position: relative;

  @media (max-width: 991px) {
    grid-template-columns: 1fr;
    grid-template-areas: 'sidebar' 'main';
    grid-template-rows: auto 1fr auto;
    grid-gap: 0;
  }
}

.sidebarContainer {
  background: white;
  box-shadow: 0px 27px 33px #00000024;
  grid-area: sidebar;
  overflow-y: auto;
  height: 100vh;
  @media (max-width: 991px) {
    height: auto;
    box-shadow: none;
  }
}

.mainContainer {
  display: grid;
  grid-template-rows: auto 1fr auto;
  overflow-y: auto;
  @media (max-width: 991px) {
    grid-template-rows: 1fr auto;
    grid-gap: 0;
  }
}

.main {
  z-index: 99;
  margin-bottom: auto;
  flex: auto;
  height: 100%;
  overflow-y: auto;
}

.mainTop {
  z-index: 100;
  position: sticky;
  top: 0;
}

.mainBottom {
  bottom: 0;
  z-index: 100;
  position: sticky;
}
