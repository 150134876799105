$color-line: #9e9e9e;

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;

  :global(.nav) {
    flex-direction: column;
  }

  div:global(.accordion-collapse) {
    div:global(.nav) {
      position: relative;
      &:before {
        content: '';
        height: 10px;
        display: block;
        width: 0;
        position: absolute;
        top: -2px;
        bottom: 0;
        left: calc(var(--bs-gutter-x) + 1rem - 0.2rem);
        border-left: 1px solid;
        border-color: $color-line;
      }
      a {
        margin-bottom: 0;
      }
      a:first-child:before {
        content: '';
        display: block;
        width: 10px;
        height: 10px;
        border-top: 1px solid;
        border-right: 1px solid;
        margin-top: -1px;
        position: absolute;
        top: 0;
        left: calc(var(--bs-gutter-x) + 1.1rem);
        border-color: $color-line;
      }
      a:before {
        content: '';
        display: block;
        width: 1px;
        height: 24px;
        border-top: 1px solid;
        border-right: 1px solid;
        margin-top: -1px;
        position: absolute;
        top: calc(0.5rem - var(--bs-gutter-x));
        left: calc(var(--bs-gutter-x) + 1.7rem);
        border-color: $color-line;
      }
    }
  }

  .sideBarHeader {
    margin-top: 4rem;
    margin-bottom: 2rem;
    a {
      text-decoration: none;
    }

    h1 {
      margin: 0 3rem;
      img {
        width: 100%;
      }
    }
  }

  .sideBarMain {
    margin-top: auto;
    margin-bottom: auto;

    a {
      margin-left: 2rem;
      svg {
        font-size: 1.5rem;
        border: 3px dashed #fff;
        border-radius: 100%;
        padding: 5px;
      }
      span {
        padding-left: 0.5rem;
      }

      &:hover {
        svg {
          border-color: var(--bs-secondary);
        }
      }
    }
  }

  .sideBarFooter {
    margin-top: auto;
    margin-bottom: 3rem;
  }
}
