$sidebar-item-border: 0.5rem;
$nav-link-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out !default;

.container {
  :global(.nav-item) {
    padding-left: 0;
  }

  a:global(.nav-link) {
    padding-left: calc(var(--bs-gutter-x) + 1rem - $sidebar-item-border + 1rem);
    margin-left: calc(-1 * var(--bs-gutter-x) / 2);

    position: relative;
    border-left-style: solid;
    border-left-width: $sidebar-item-border;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;

    color: #9e9e9e;
    border-left-color: transparent;

    &.subItem svg {
      font-size: 0.4rem;
      margin-bottom: 0.2rem;
      margin-right: 0.2rem;
    }

    /**
       * active and hover
       */
    &:global(.active) {
      color: #333;
      svg {
        color: var(--bs-secondary);
      }
    }

    &:global(:not(.active)):hover {
      color: #333;
      svg {
        color: var(--bs-secondary);
      }
    }
  }
  a:global(.nav-link):not(.subItem) {
    padding-left: calc(var(--bs-gutter-x) + 1rem - $sidebar-item-border);
    margin-left: calc(-1 * var(--bs-gutter-x) / 2);
    // border: 1px solid red;

    svg {
      width: 2rem;
      font-size: 1rem;
      margin-bottom: 0;
    }

    /**
     * rounded borders (doing a circle doubling the size and showing a quarter)
     */
    &:after,
    &:before {
      transition: $nav-link-transition;
      content: '';
      position: absolute;
      width: calc(2 * $sidebar-item-border);
      height: calc(2 * $sidebar-item-border);
      border-radius: 100%;
    }
    &:after {
      left: calc(-2 * $sidebar-item-border);
      top: calc(-1 * $sidebar-item-border);
    }
    &:before {
      left: calc(-2 * $sidebar-item-border);
      bottom: calc(-1 * $sidebar-item-border);
    }

    /**
     * active and hover
     */
    &:global(.active) {
      border-left-color: var(--bs-secondary);
      &:after,
      &:before {
        background-color: var(--bs-secondary);
      }
    }
  }
}
